import { Auth } from "aws-amplify";
const aws_config = {
    
    aws_cognito_region: "eu-central-1", // (required) - Region where Amazon Cognito project was created   
    aws_user_pools_id:  "eu-central-1_VNrfFNugG", // (optional) -  Amazon Cognito User Pool ID   
    aws_user_pools_web_client_id: "7ii7hi4qnef202ostk2lrc76a2", // (optional) - Amazon Cognito App Client ID (App client secret needs to be disabled)
    aws_cognito_identity_pool_id: "eu-central-1:49beb523-82dc-49e6-84cd-e9ee5368306c", // (optional) - Amazon Cognito Identity Pool ID   
    aws_mandatory_sign_in: "enable", // (optional) - Users are not allowed to get the aws credentials unless they are signed in   
    API: {
        endpoints: [
            {
                name: "ThermonovaAPI",
                endpoint: "https://ldgxicl4j4.execute-api.eu-central-1.amazonaws.com/v1",
                custom_header: async () => { 
                     return { Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}` }
                  }
            }]
    }
};

export default aws_config;