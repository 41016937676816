import React from 'react'
import ChooseControllerLogic from './ChooseControllerLogic'


export default function ChooseControllerView() {
    const {showControllerButtons} = ChooseControllerLogic()
  return (
    <div>{showControllerButtons()}</div>
  )
}
