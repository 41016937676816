import React, { useRef } from "react";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";

import TableRow from "@mui/material/TableRow";
import { useSelector } from "react-redux";

export default function Hotspots(props: string) {
  const {
    masterHotspots,
    modul1Hotspots,
    modul2Hotspots,
    modul3Hotspots,
    modul4Hotspots,
  } = useSelector((state: any) => state.heatpump);
  const Hotspots = useRef([]);

  const setHotspots = (e: any) => {
    Hotspots.current = e;
    //console.log(e);
  };

  switch (props) {
    case "master":
      //console.log(masterSettings)
      setHotspots(masterHotspots);
      break;
    case "modul1":
      setHotspots(modul1Hotspots);
      break;
    case "modul2":
      setHotspots(modul2Hotspots);
      break;
    case "modul3":
      setHotspots(modul3Hotspots);
      break;
    case "modul4":
      setHotspots(modul4Hotspots);
      break;

    default:
      break;
  }

  const calculateDecimal = (dec: string, value: string) => {
    if (value === "") {
      return "";
    } else if (value === "32767") {
      return " - "
    }

    else {
      switch (dec) {
        case "0":
          return parseFloat(String(parseInt(value))).toFixed(parseInt(dec));
        case "1":
          return parseFloat(String(parseInt(value) / 10)).toFixed(
            parseInt(dec)
          );
        case "2":
          return parseFloat(String(parseInt(value) / 100)).toFixed(
            parseInt(dec)
          );
        case "3":
          return parseFloat(String(parseInt(value) / 1000)).toFixed(
            parseInt(dec)
          );
        case "4":
          return parseFloat(String(parseInt(value) / 10000)).toFixed(
            parseInt(dec)
          );

        default:
          return String(parseInt(value));
      }
    }
  };

  const getValue = ( value: any) => {
    if (Object.keys(value.col5.enum).length !== 0) {
      return value.col5.enum[value.col3]
    }
    else{
      //console.log(value)
      return (calculateDecimal(value.col5.Dec, value.col3) + " " + value.col5.Unit)
    }
  }

  const tablebody = (
    <TableBody>
      {Hotspots.current.map((row: any) => (
        <TableRow
          key={row.id}
          sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
        >
          <TableCell component="th" scope="row" align="right">
            {row.col1}
          </TableCell>
          <TableCell>{row.col2}</TableCell>
          <TableCell align="right">
            {getValue(row)}
            
          </TableCell>
        </TableRow>
      ))}
    </TableBody>
  );

  return { tablebody };
}
