import React from 'react'
import OverviewGwStatus from './gwStatusLogic'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Paper from "@mui/material/Paper";

import { styled } from "@mui/material/styles";


export default function GwStatusView() {
    const {showupdateGateway,vpnIP, lastUpdate,renderSoftwareVersionLines} = OverviewGwStatus()
    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
        ...theme.typography.body2,
        padding: theme.spacing(1),
        color: theme.palette.text.secondary,
      }));
  return (
    <Item>
        <Table size="small" aria-label="a dense table">
            <TableBody>
                {renderSoftwareVersionLines()}
                <TableRow key="LastSendData" sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
                    <TableCell align="left">Last update</TableCell>
                    <TableCell align="right">{lastUpdate}</TableCell>
                </TableRow>
                <TableRow key="VPNip" sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
                    <TableCell align="left">VPN IP</TableCell>
                    <TableCell align="right">{vpnIP}</TableCell>
                </TableRow>
            
            </TableBody>
        </Table>
        <p></p>
        {showupdateGateway}
    </Item>
  )
}
