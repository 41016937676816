import React from 'react'
import { useSelector } from "react-redux";
import Typography from '@mui/material/Typography';

import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";

export const DashboardLogic = () => {

    const heatpumps = useSelector((state : any) => state.mainData.heatpumps);
    const [hpWithError, setHpWithError] = React.useState([]);
    const [hpOnline, setHpOnline] = React.useState([]);

    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
        ...theme.typography.body2,
        padding: theme.spacing(1),
        color: theme.palette.text.secondary,
      }));

    React.useEffect(() => {
        var listErrorHP = []
        var listHPOnline = []
        for (const property in heatpumps) {
            if(heatpumps[property].alarm === 'true'){
                //console.log(heatpumps[property]);
                listErrorHP.push(heatpumps[property])
            }


            var ts = Date.now() - 900000;
            var onlineTs = Date.parse(heatpumps[property].col13);
            if(onlineTs >= ts){
                //console.log(heatpumps[property]);
                listHPOnline.push(heatpumps[property])
            }
            
          }
          setHpWithError(listErrorHP)
          setHpOnline(listHPOnline)
    
      
    }, [heatpumps])
    

    const viewNumberofheatpumps = (
        <Item style={{height : '200px'}}>
            <Typography variant="h4" align="center">
                Varmepumper
            </Typography>
            <p style={{padding : '10px'}}></p>
            <Typography variant="h5" gutterBottom align="center"> 
            {Object.keys(heatpumps).length}
            </Typography>
            
        </Item>  
        )
        const viewNumberofheatpumpsOnline = (
            <Item style={{height : '200px'}}>
                <Typography variant="h4" align="center">
                    Varmepumper
                </Typography>
                <Typography variant="h4" align="center">
                    Online
                </Typography>
                <p style={{padding : '10px'}}></p>
                <Typography variant="h5" gutterBottom align="center"> 
                {Object.keys(hpOnline).length}
                </Typography>
                
            </Item>  
            )
        const viewNumberofheatpumpsWithError = (
            <Item style={{height : '200px'}}>
                <Typography variant="h4" align="center">
                    Varmepumper
                </Typography>
                <Typography variant="body1" align="center">
                    med
                </Typography>
                <Typography variant="h4" align="center">
                    Fejl
                </Typography>
                <p style={{padding : '5px'}}></p>
                <Typography variant="h5" gutterBottom align="center"> 
                {hpWithError.length}
                </Typography>
                
            </Item>  
            )
    

  return {viewNumberofheatpumps, viewNumberofheatpumpsWithError, viewNumberofheatpumpsOnline}
}
