import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { EditCLLogic } from "./EditContactListLogic";
import CircularProgress from "@mui/material/CircularProgress";
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";

export default function EditHPView(props: any) {
  const {
    handleSend,
    returnAlert,
    loading,
    handleChange,
    email,
    name,
    language,
    phone,
    rows,
    columns,
    serviceContact,
    handleAddServiceContact
  } = EditCLLogic(props.HPdata);

  return (
    <div>
      <Dialog
        open={props.open}
        onClose={props.handleClose}
        fullWidth
        maxWidth="lg"
      >
        <DialogTitle>Contact List</DialogTitle>
        <DialogContent>

          <Box sx={{ height: 400, width: "100%" }}>
            {/* <Card raised sx={{ height: 400, width: "100%" }}> */}
            <DataGrid
              disableSelectionOnClick
              rows={rows}
              columns={columns}
            />
            {/* </Card> */}
          </Box>
        </DialogContent>
        <DialogActions sx={{ justifyContent: "flex-start", paddingLeft: 3 }}>
        <TextField
            autoFocus
            margin="dense"
            id="name"
            name="name"
            value={name}
            label="Name"
            type="text"
            onChange={handleChange}
            variant="outlined"
          />
          <TextField
            autoFocus
            margin="dense"
            id="email"
            name="email"
            value={email}
            label="Mail"
            type="email"
            onChange={handleChange}
            variant="outlined"
          />
          <TextField
            margin="dense"
            id="phone"
            name="phone"
            value={phone}
            label="+4512345678"
            type="text"
            onChange={handleChange}
            variant="outlined"
          />
          <Select
          id="language"
          name="language"
          value={language}
          label="Language"
          onChange={handleChange}
        >
          <MenuItem value={"DK"}>DK</MenuItem>
          <MenuItem value={"EN"}>EN</MenuItem>
          <MenuItem value={"DE"}>DE</MenuItem>
        </Select>
          <Button
            disabled={(!email && !phone) || loading}
            variant="contained"
            onClick={handleSend}
            sx={{ marginLeft: 2 }}
          >
            {!loading && <span>Add</span>}
            {loading && <CircularProgress size={20} />}
          </Button>
          <Button
            disabled={serviceContact}
            variant="contained"
            onClick={handleAddServiceContact}

          >
            Add Service contact
          </Button>
        </DialogActions>
        
        {returnAlert()}
      </Dialog>
    </div>
  );
}
