import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { EditDatasetLogic } from "./EditDatasetLogic";
import ButtonGroup from '@mui/material/ButtonGroup';

import Grid from "@mui/material/Grid";
import List from "@mui/material/List";

export default function EditDatasetView(webWorker: any) {
  const {
    getParamsList,
    getDatasetList,
    showColorPicker,
    openEditDatasetModal,
    handleCloseEditDatasetModal,
    handleChangeDataset
  } = EditDatasetLogic(webWorker.webWorker);

  

  return (
    <div>
      <Dialog
        fullWidth
        maxWidth={"lg"}
        open={openEditDatasetModal}
        onClose={handleCloseEditDatasetModal}
      >
        <DialogTitle>Edit Dataset</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={5} style={{ height: "80vh", overflow: "auto" }}>
              <List
                sx={{
                  width: "100%",
                  maxWidth: 400,
                  bgcolor: "background.paper",
                }}
                component="nav"
              >
                {getParamsList()}
              </List>
            </Grid>
            <Grid item xs={7}>
              {getDatasetList()}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <ButtonGroup size="small" variant="text" aria-label="text button group small ">

          <Button disabled={false} onClick={() => handleChangeDataset("Master")}>
              Master View
            </Button>
            <Button disabled={false} onClick={() => handleChangeDataset("Modul1")}>
              Modul 1 View
            </Button>
            <Button disabled={false} onClick={() => handleChangeDataset("Modul2")}>
              Modul 2 View
            </Button>
            <Button disabled={false} onClick={() => handleChangeDataset("Modul3")}>
              Modul 3 View
            </Button>
            <Button disabled={false} onClick={() => handleChangeDataset("Modul4")}>
              Modul 4 View
            </Button>
          </ButtonGroup>

          <Button disabled={false} onClick={handleCloseEditDatasetModal}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
      {showColorPicker()}
    </div>
  );
}
