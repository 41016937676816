import React from "react";

import logo from "./img/png/THERMONOVA_LOGO_H250PX.png";
import "./App.css";
import {
  AmplifyAuthenticator,
  AmplifySignUp,
  AmplifySignIn,
  AmplifyConfirmSignIn,
  AmplifyConfirmSignUp,
  AmplifyForgotPassword,
} from "@aws-amplify/ui-react";
import { AuthState, onAuthUIStateChange } from "@aws-amplify/ui-components";

import Mainview from "./components/MainView/Mainview";
import { Amplify, Auth, Hub } from "aws-amplify";
import AMPLIFY_CONFIG from "./aws-exports";
import { Routes, Route } from "react-router-dom";
import { AWSIoTProvider } from "@aws-amplify/pubsub";

import { Provider } from "react-redux";
import store from "./components/Redux/Store";

import HP from "./components/HP";
import { LicenseInfo } from "@mui/x-data-grid-pro";

LicenseInfo.setLicenseKey(
  "27f5d0b99561b6c8da97151d6b620650T1JERVI6Mzc4OTQsRVhQSVJZPTE2NzY1NDg5MjMwMDAsS0VZVkVSU0lPTj0x"
);

Amplify.configure(AMPLIFY_CONFIG);

Amplify.addPluggable(
  new AWSIoTProvider({
    aws_pubsub_region: "eu-central-1",
    aws_pubsub_endpoint:
      "wss://a2bzxfhckf71q1-ats.iot.eu-central-1.amazonaws.com/mqtt",
  })
);

function App() {
  const [showLogo, setShowLogo] = React.useState("none");
  const [authState, setAuthState] = React.useState<AuthState>();
  const [user, setUser] = React.useState<object>();
  const [vieporth, setVieporth] = React.useState<number>();

  React.useEffect(() => {
    document.title = "Dashboard - Thermonova A/S";
  }, []);

  async function updateUserAttributes(user: object, identID: string) {
    await Auth.updateUserAttributes(user, {
      "custom:IdentityID": identID,
    });
    //console.log(result); // SUCCESS
  }

  React.useEffect(() => {
    setVieporth(window.innerHeight / 2 - 350);
    if (user !== undefined) {
      setShowLogo("none");
      Auth.currentAuthenticatedUser( {bypassCache: false}).then((response) => {

        if (!("custom:IdentityID" in response.attributes)) {
          Auth.currentUserInfo().then((response2) => {
            updateUserAttributes(response, response2.id);
          });
        }
        if('cognito:groups' in response['signInUserSession']['accessToken']['payload']){
          if(response['signInUserSession']['accessToken']['payload']['cognito:groups'].includes('Administrator')){
            
          }
          else{
            signOut()
            //window.location.replace('https://my.thermonova.dk')
          }
        }else{
          signOut()
          //window.location.replace('https://my.thermonova.dk')
        }
        
      });
    }
    return onAuthUIStateChange((nextAuthState, authData) => {
      setAuthState(nextAuthState);
      setUser(authData);
      setShowLogo("block");
    });
  }, [user]);

  const signOut = async () => {
    try {
      await Auth.signOut();
      Hub.dispatch("UI Auth", {
        // channel must be 'UI Auth'
        event: "AuthStateChange", // event must be 'AuthStateChange'
        message: "signedout", // message must be 'signedout'
      });
    } catch (error) {
      console.log("error signing out: ", error);
    }
  };
  const loggedInView = (
    <div className="App">
      <Mainview logOut={signOut} />
      
    </div>
  );
  return authState === AuthState.SignedIn && user ? (
    
    <Provider store={store}>
      <Routes>
        <Route path="/" element={loggedInView} />
        <Route path="/HP" element={<HP />} />
      </Routes>
    </Provider>
  ) : (
    <div>
      <div>
        <img
          src={logo}
          style={{
            width: "20rem",
            position: "absolute",
            top: vieporth,
            left: "calc(50% - 10rem)",
            display: showLogo,
          }}
          alt="Thermonova A/S"
        />
      </div>

      <AmplifyAuthenticator>
        <AmplifySignIn slot="sign-in" usernameAlias="email" />
        <AmplifyConfirmSignIn
          //  usernameAlias="email"
          slot="confirm-sign-in"
        />
        <AmplifyConfirmSignUp usernameAlias="email" slot="confirm-sign-up" />
        <AmplifyForgotPassword usernameAlias="email" slot="forgot-password" />
        <AmplifySignUp
          slot="sign-up"
          usernameAlias="email"
          formFields={[
            {
              type: "email",
              label: "Email Address",
              placeholder: "Enter your email address",
              inputProps: { required: true, autocomplete: "username" },
            },
            {
              type: "password",
              label: "New password",
              placeholder: "Enter a new password",
              inputProps: { required: true, autocomplete: "new-password" },
            },
          ]}
        />
      </AmplifyAuthenticator>
    </div>
  );
}

export default App;
