import React from "react";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import { useSelector, useDispatch } from "react-redux";
import { setChooseController, setControllerIsSet } from "../Redux/HP";

export default function ChooseControllerLogic() {
  const { HPData, chooseController } = useSelector(
    (state: any) => state.heatpump
  );
  const dispatch = useDispatch();

  const handleChooseController = (e: any) => {
    switch (e.target.value) {
      case "1":
        dispatch(setChooseController({ "1": true, "2": false, "3": false, "4": false, "5": false, "100": false }));
        dispatch(setControllerIsSet(1));
        break;
      case "2":
        dispatch(setChooseController({ "1": false, "2": true, "3": false, "4": false, "5": false, "100": false }));
        dispatch(setControllerIsSet(2));

        break;
      case "3":
        dispatch(setChooseController({ "1": false, "2": false, "3": true, "4": false, "5": false, "100": false }));
        dispatch(setControllerIsSet(3));

        break;
      case "4":
        dispatch(setChooseController({ "1": false, "2": false, "3": false, "4": true, "5": false, "100": false }));
        dispatch(setControllerIsSet(4));

        break;
      case "5":
        dispatch(setChooseController({ "1": false, "2": false, "3": false, "4": false, "5": true, "100": false }));
        dispatch(setControllerIsSet(5));

        break;
      case "100":
        dispatch(setChooseController({ "1": false, "2": false, "100": true }));
        dispatch(setControllerIsSet(100));

        break;

      default:
        dispatch(setChooseController({ "1": false, "2": false, "3": false, "4": false, "5": false, "100": false }));
        dispatch(setControllerIsSet(false));
        break;
    }

    //console.log(chooseController)
  };

  const showControllerButtons = () => {
    let numberOfControllers = {};
    switch (HPData.Model) {
      case "Nova 440":
        numberOfControllers = {
          "1": "Slave 1",
          "2": "Slave 2",
          "100": "Master",
        };
        break;
      case "Nova 330":
        numberOfControllers = {
          "1": "Slave 1",
          "2": "Slave 2",
          "100": "Master",
        };
        break;
      case "Nova 220":
        numberOfControllers = { "1": "Slave 1", "100": "Master" };
        break;
      case "Nova 110":
        numberOfControllers = { "1": "Slave 1", "100": "Master" };
        break;
      case "Nature Energy":
        numberOfControllers = { "1": "Master", "2": "Salve 1", "3": "Salve 2", "4": "Salve 3", "5": "Salve 4" };
        break;

      default:
        numberOfControllers = { "1": "Master" };
        break;
    }
    return (
      <Stack sx={{ width: "100%" }} spacing={2}>
        {Object.entries(numberOfControllers).map(([key, value]) => {
          return (
            <Button
              onClick={handleChooseController}
              key={key}
              value={key}
              variant={chooseController[key] ? "contained" : "outlined"}
            >
              {value}
            </Button>
          );
        })}
      </Stack>
    );
  };
  return { showControllerButtons };
}
