import React from "react";
import Paper from "@mui/material/Paper";
import AddSoftwareView from "./AddSoftware/AddSoftwareView";

import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import AddIcon from "@mui/icons-material/Add";
import { SoftwareControlLogic } from "./SoftwareControlLogic";

export default function BasicTable(props: any) {
  const {
    rows,
    columns,
    QuickSearchToolbar,
    searchText,
    requestSearch,
    open,
    handleClose,
    handleOpen,
  } = SoftwareControlLogic();

  return (
    <div>
      <Paper sx={{ width: "100%", overflow: "hidden", position: "relative" }}>
        <Tooltip title="Add Gateway" placement="top">
          <Button
            sx={{ position: "absolute", top: 15, right: 15, zIndex: 2 }}
            onClick={handleOpen}
            variant="contained"
          >
            <AddIcon style={{ fontSize: 20, marginRight: 5 }} />
            Upload Software
          </Button>
        </Tooltip>
        <Box sx={{ height: props.height, width: 1 }}>
          <DataGrid
            disableSelectionOnClick
            components={{ Toolbar: QuickSearchToolbar }}
            rows={rows}
            columns={columns}
            componentsProps={{
              toolbar: {
                value: searchText,
                onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
                  requestSearch(event.target.value);
                },
                clearSearch: () => requestSearch(""),
              },
            }}
          />
        </Box>

        <AddSoftwareView open={open} handleClose={handleClose} />
      </Paper>
    </div>
  );
}
