import React from "react";
import AlarmLogic from "./AlarmLogic";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {Accordion, AccordionSummary, AccordionDetails, Typography, Alert, CircularProgress} from "@mui/material";




export default function AlarmView(props) {
  const {  Item, expanded, expanded2, handleChange, loading, activeAlarms, resolvedAlarms, openGraph, setExpanded2, getMasterSlaveView} = AlarmLogic(props);
  
  return (
    <div>
      <Item>
      <Accordion
        style={{ boxShadow: "none" }}
        expanded={expanded}
        onChange={handleChange()}
      >
        <AccordionSummary
          aria-controls="panel1a-content"
          id="panel1a-header"
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>
            Active Errors
            {loading && (
              <CircularProgress size={12} style={{ marginLeft: 15 }} />
            )}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          {Object.keys(activeAlarms).map((key: any, index: any) => (
            <Alert
              severity={
                activeAlarms[key].Type === "Warning" ? "warning" : "error"
              }
              style={{
                borderRadius: 0,
              }}
              key={key}
            >
              <span
                style={{
                  marginRight: 10,
                  cursor: "pointer",
                  textDecoration: "underline",
                }}
                onClick={() => openGraph(activeAlarms[key].CreatedTimestamp)}
              >
                {new Date(activeAlarms[key].CreatedTimestamp).toLocaleString()}
              </span>
              {getMasterSlaveView(activeAlarms[key].NodeID)}
              <span>{activeAlarms[key].Name}</span>
            </Alert>
          ))}
        </AccordionDetails>
      </Accordion>
      <Accordion
        style={{ boxShadow: "none" }}
        expanded={expanded2}
        onChange={() => setExpanded2(!expanded2)}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>Resolved Errors</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {Object.keys(resolvedAlarms).map((key: any, index: any) => (
            <Alert severity="success" style={{ borderRadius: 0 }} key={key}>
              <span
                style={{
                  marginRight: 10,
                }}
              >
                {new Date(resolvedAlarms[key].CreatedTimestamp).toLocaleString()}
              </span>
              <span style={{ marginRight: 10 }}>
                {new Date(resolvedAlarms[key].ResetTimestamp).toLocaleString()}
              </span>
              {getMasterSlaveView(resolvedAlarms[key].NodeID)}
              <span>{resolvedAlarms[key].Name}</span>
            </Alert>
          ))}
        </AccordionDetails>
      </Accordion>
    </Item>
    </div>
  );
}
