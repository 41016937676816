import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
//import OverviewLogic from "./OverviewLogic";
import GwStatusView from "./gwStatusView";
import LogView from "./LogView";
import AlarmView from "./AlarmView";
import HPInformations from "./HPInformations/HPInformationsView"


export default function Overview(props) {

  return (
          <Box id="SWUploadViewBox" sx={{ flexGrow: 1 }}>
            <Grid container spacing={3} >
              <Grid item xs={12} md={8} lg={9} >
                <Grid container spacing={3} >
                  
                  <Grid item xs={12} md={12} lg={12}>
                    <AlarmView {...props}/>
                  </Grid>
                  <Grid item xs={12} md={12} lg={12} >
                    <LogView />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={4} lg={3}>
                <Grid container spacing={3} >
                  <Grid item xs={12} >
                    <GwStatusView />
                  </Grid>
                  <Grid item xs={12} >
                    <HPInformations />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>

  );
}
