

import { useSelector, useDispatch } from "react-redux";
import {
  setXDuration,
  setLiveData,
  setXmin,
  setXmax,
  setXnextUpdate,
  setXsteps,
} from "../Redux/HP";
import { SelectChangeEvent } from "@mui/material";
import dayjs from 'dayjs';



export default function XAxisLogic() {
  const { xmin, xmax, xDuration, liveData } = useSelector(
    (state: any) => state.heatpump
  );
  const dispatch = useDispatch();

  const handleTimeChange = (e: string) => {
    switch (e) {
      case "timeBackward":
        dispatch(setLiveData(false));
        dispatch(setXmin(xmin - xDuration / 2));
        //setXnextUpdate(date+((xDuration/2)-60000))
        dispatch(setXmax(xmax - xDuration / 2));
        break;
      case "timeForward":
        dispatch(setXmin(xmin + xDuration / 2));
        if (xmax + xDuration / 2 > Date.now()) {
          dispatch(setLiveData(true));
          dispatch(setXnextUpdate(xmax + (xDuration / 2 - 60000)));
        }
        dispatch(setXmax(xmax + xDuration / 2));
        break;
      case "timeLive":
        dispatch(setLiveData(true));
        var date = Date.now();
        dispatch(setXmin(date - xDuration / 2));
        dispatch(setXnextUpdate(date + (xDuration / 2 - 60000)));
        dispatch(setXmax(date + xDuration / 2));
        break;

      default:
        break;
    }
  };

  const setXMinutes = (e: SelectChangeEvent<"number">) => {
    var value = Number(e.target.value);
    dispatch(setXDuration(value));
    var date = xmin + xDuration / 2;
    dispatch(setXmin(date - value / 2));
    dispatch(setXnextUpdate(date + (value / 2 - 60000)));
    dispatch(setXmax(date + value / 2));

    switch (value) {
      case 300000:
        dispatch(setXsteps(30000));
        break;
      case 600000:
        dispatch(setXsteps(60000));
        break;
      case 900000:
        dispatch(setXsteps(120000));
        break;
      case 1800000:
        dispatch(setXsteps(180000));
        break;
      case 3600000:
        dispatch(setXsteps(600000));
        break;
      case 7200000:
        dispatch(setXsteps(900000));
        break;
      case 10800000:
        dispatch(setXsteps(1800000));
        break;
      case 21600000:
        dispatch(setXsteps(2700000));
        break;
      case 43200000:
        dispatch(setXsteps(3600000));
        break;
      case 86400000:
        dispatch(setXsteps(7200000));
        break;

      default:
        dispatch(setXsteps(120000));
        break;
    }
  };

  const actualDate = dayjs(new Date(xmin))





    const changeDateFromPicker = (date) => {
      var ts = date.toDate().getTime();
        //console.log(date.toDate().getTime())
        dispatch(setLiveData(false));
        dispatch(setXmin(ts - xDuration / 2));
        //setXnextUpdate(date+((xDuration/2)-60000))
        dispatch(setXmax(ts + xDuration / 2));

    }

  return { actualDate, xDuration, liveData, handleTimeChange, setXMinutes, changeDateFromPicker };
}
