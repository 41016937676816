import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { API } from "aws-amplify";
import NovaView from "./Nova/index";
import GeoNovaView from "./GeoNova/index";
import NatureEnergyView from "./NatureEnergy/index";

import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { Auth, Hub } from "aws-amplify";
import { setHPData } from "./Redux/HP";
// import { useAppDispatch } from "../../hooks/redux";
import { useDispatch } from "react-redux";

export default function HPLogic() {
  const Dispatch = useDispatch();

  const [serialnumber] = useState(useQuery().get("serialnumber"));
  const [HPData, setData] = useState({});
  const [loadingScreen, setLoadingScreen] = useState(true);
  const loading = (
    <Backdrop
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={loadingScreen}
      onClick={() => {
        setLoadingScreen(false);
      }}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );
  const [view, setView] = useState(loading);
  const [dataLoaded, setDataLoaded] = useState(false);

  React.useEffect(() => {
    document.title = "Heatpump - Thermonova A/S";
    if (serialnumber) getHPData(serialnumber);

    async function getHPData(serialnumber: string) {
      const apiName = "ThermonovaAPI";
      const path = "/HP";
      const output: any[] = [];
      await API.get(apiName, path, {
        queryStringParameters: { Serialnumber: serialnumber },
      })
        .then((response) => {
          setData(response);
          Dispatch(setHPData(response));
          setDataLoaded(true);

          document.title = response.Name + " - " + response.Model;
        })
        .catch((error) => {
          console.log(error.response);
        });
      return output;
    }
  }, [serialnumber, Dispatch]);

  React.useEffect(() => {
    if (dataLoaded) {      
      if(HPData['Model'].includes("Geo") ){
        setView(<GeoNovaView logOut={signOut} HPData={HPData} />);
      }else if(HPData['Model'].includes("Nature Energy") ) {
        setView(<NatureEnergyView logOut={signOut} HPData={HPData} />);
      }else if(HPData['Model'].includes("Testchamber") ) {
        setView(<GeoNovaView logOut={signOut} HPData={HPData} />);
      }else if(HPData['Model'].includes("Nova") ) {
        setView(<NovaView logOut={signOut} HPData={HPData} />);
      }
      else{
        setView(<NovaView logOut={signOut} HPData={HPData} />);
      }
      
    }
  }, [dataLoaded, HPData]);

  async function signOut() {
    try {
      await Auth.signOut().then((response) => {
        Hub.dispatch("UI Auth", {
          // channel must be 'UI Auth'
          event: "AuthStateChange", // event must be 'AuthStateChange'
          message: "signedout", // message must be 'signedout'
        });
      });
    } catch (error) {
      console.log("error signing out: ", error);
    }
  }

  function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

  return { view };
}
