//import { HPData } from "./../../../types";
/* eslint-disable array-callback-return */

import React, { useState, useRef } from "react";
import { PubSub, Auth, API } from "aws-amplify";

//import { useAppDispatch } from "../../../hooks/redux";
import { useDispatch } from "react-redux";
import {
  setLastUpdate,
  setvpnIP,
  setActiveAlarms,
  setResolvedAlarms,
  setMasterSettings,
  setModul1Settings,
  setModul2Settings,
  setModul3Settings,
  setModul4Settings,
  setMasterHotspots,
  setModul1Hotspots,
  setModul2Hotspots,
  setModul3Hotspots,
  setModul4Hotspots,
  setGraphParamList,
  setGraphData,
  setLogs,
  setHPData,
  setUpdateGWState,
  setLiveDataTopic,
  setHandlerTopic,
  setGwDataTopic,
  setNewSoftwareTopic,
  setChangeSettingsState,
  setCognitoUsername,
  setMcxFileUploadProgress,
  setMcxFileOnGateway,
  setMcxGWRestarted,
  setChartDataset,
  setLiveDataset,
  setDisabledLineGraphs
} from "../Redux/HP";

export default function NovaLogic(HPData) {
  const dispatch = useDispatch();
  //   console.log({ HPData });
  const controllerDescription = HPData.Controller;
  const HPModel = HPData.Model;
  const liveDataTopic = "HP/" + HPData.Serialnumber + "/LiveData";
  const handlerTopic = "HP/" + HPData.Serialnumber + "/handler";
  const gwDataTopic = "HP/" + HPData.Serialnumber + "/gwData";
  const newSoftwareTopic = "HP/" + HPData.Serialnumber + "/mcxSoftware";
  const changedDataTopic = "HP/" + HPData.Serialnumber + "/dataChanged";

  const [webWorker, setWebWorker] = useState<any>();
  const [username, setUsername] = useState<any>("");

  //console.log(logs)

  var MCXLiveData = useRef(true);

  const handleUpdateGatewayAfterMCXUpdate = () => {
    timeout(40000).then(() => {
      PubSub.publish(handlerTopic, { updateGW: "True" });
    });
  };

  React.useEffect(() => {
    var d = new Date(HPData.OnlineTimeStamp);
    var ts = d.toLocaleTimeString() + " - " + d.toLocaleDateString();
    dispatch(setLastUpdate(ts));
    dispatch(setLogs(HPData.Logs));
    dispatch(setHPData(HPData));
    dispatch(setLiveDataTopic(liveDataTopic));
    dispatch(setHandlerTopic(handlerTopic));
    dispatch(setGwDataTopic(gwDataTopic));
    dispatch(setNewSoftwareTopic(newSoftwareTopic));
  }, [
    dispatch,
    HPData,
    gwDataTopic,
    handlerTopic,
    liveDataTopic,
    newSoftwareTopic,
  ]);

  React.useEffect(() => {
    const savedData = JSON.parse(
      localStorage.getItem(HPData.Serialnumber) || "{}"
    );
    //console.log(savedData)
    if (savedData) {
      if (savedData.chartDataset) {
        dispatch(setChartDataset(savedData.chartDataset));
      }
      if (savedData.liveDataset) {
        dispatch(setLiveDataset(savedData.liveDataset));
      }
      if (savedData.disabledLineGraphs) {
        dispatch(setDisabledLineGraphs(savedData.disabledLineGraphs));
      }
    }
  }, [dispatch, HPData]);


  React.useEffect(() => {

    function alphabetical_sort_object_of_objects(data, attr) {
      var arr = [];
      for (var prop in data) {
          if (data.hasOwnProperty(prop)) {
              var obj = {};
              obj[prop] = data[prop];
              obj['tempSortName'] = data[prop][attr].toLowerCase();
              arr.push(obj);
          }
      }
  
      arr.sort(function(a, b) {
          var at = a.tempSortName,
              bt = b.tempSortName;
          return at < bt ? 1 : ( at > bt ? -1 : 0 );
      });
  
      var result = [];
      for (var i=0, l=arr.length; i<l; i++) {
          var obj2 = arr[i];
          delete obj2.tempSortName;
          for (var prop2 in obj2) {
              if (obj2.hasOwnProperty(prop2)) {
                  var id = prop2;
              }
          }
          var item = obj2[id];
          result.push(item);
      }
      return result;
  }

    const getAlarms = () => {
      const apiName = "ThermonovaAPI";
      const path = "/HP/alarmlog";
      const myInit = {
        queryStringParameters: {
          Serialnumber: HPData.Serialnumber,
        },
      };
  
      API.get(apiName, path, myInit)
        .then((response) => {
          if (response?.ActiveError) {
            // dispatch setActiveErrors(response.ActiveError);
            
            //console.log(alphabetical_sort_object_of_objects(response.ActiveError,'CreatedTimestamp'))
            dispatch(setActiveAlarms(alphabetical_sort_object_of_objects(response.ActiveError,'CreatedTimestamp')));
          }
          if (response?.OldError) {
            // dispatch setOldErrors(response.OldError);
            dispatch(setResolvedAlarms(alphabetical_sort_object_of_objects(response.OldError,'CreatedTimestamp')));
          }
        })
        .catch((error) => {
          console.log(error);
        })
        
    };
    getAlarms()
    const interval = setInterval(() => {
      getAlarms()
    }, 60000);
  
    return () => {
      clearInterval(interval);
    }
  }, [HPData, dispatch])
  

  React.useEffect(() => {
    const getParams = (ModulName: string) => {
      let ID: number;
      let ModulNumb: string;
      const settingList: any[] = [];
      const hotspotList: any[] = [];

      switch (ModulName) {
        case "Master":
          ID = 100;
          ModulNumb = "MCX";
          break;
        case "Modul1":
          ID = 1;
          ModulNumb = "1";
          break;
        case "Modul2":
          ID = 1;
          ModulNumb = "2";
          break;
        case "Modul3":
          ID = 2;
          ModulNumb = "1";
          break;
        case "Modul4":
          ID = 2;
          ModulNumb = "2";
          break;
        default:
          ID = 1;
          break;
      }
      //console.log(controllerDescription)
      Object.entries(controllerDescription[ID]["data"]).map(
        ([modbusAdr, value]: any) => {
          if (ID === 1 || ID === 2) {
            if (value["Modulnumber"] === ModulNumb) {
              if (value["ReadWrite"] === "rw") {
                settingList.push({
                  id: modbusAdr,
                  col1: modbusAdr,
                  col2: value["Name"],
                  col3: value["Default"],
                  col4: "",
                  col5: value,
                });
              } else {
                hotspotList.push({
                  id: modbusAdr,
                  col1: modbusAdr,
                  col2: value["Name"],
                  col3: "",
                  col5: value,
                });
              }
            } else if (
              value["Modulnumber"] === null ||
              value["Modulnumber"] === "MCX"
            ) {
              if (value["ReadWrite"] === "rw") {
                settingList.push({
                  id: modbusAdr,
                  col1: modbusAdr,
                  col2: value["Name"],
                  col3: value["Default"],
                  col4: "",
                  col5: value,
                });
              } else {
                hotspotList.push({
                  id: modbusAdr,
                  col1: modbusAdr,
                  col2: value["Name"],
                  col3: "",
                  col5: value,
                });
              }
            }
          } else if (ID === 100) {
            if (value["ReadWrite"] === "rw") {
              settingList.push({
                id: modbusAdr,
                col1: modbusAdr,
                col2: value["Name"],
                col3: value["Default"],
                col4: "",
                col5: value,
              });
            } else {
              hotspotList.push({
                id: modbusAdr,
                col1: modbusAdr,
                col2: value["Name"],
                col3: "",
                col5: value,
              });
            }
          }
        }
      );
      //console.log({"setting":settingList, "hotspot": hotspotList})
      return { setting: settingList, hotspot: hotspotList };
    };

    switch (HPModel) {
      case "Nova 110":
        dispatch(setMasterSettings(getParams("Master")["setting"]));
        dispatch(setModul1Settings(getParams("Modul1")["setting"]));
        dispatch(setModul2Settings(getParams("Modul2")["setting"]));
        dispatch(setMasterHotspots(getParams("Master")["hotspot"]));
        dispatch(setModul1Hotspots(getParams("Modul1")["hotspot"]));
        dispatch(
          setGraphParamList({
            MS: getParams("Master")["setting"],
            MH: getParams("Master")["hotspot"],
            S1S: getParams("Modul1")["setting"],
            S1H: getParams("Modul1")["hotspot"],
          })
        );
        break;
      case "Nova 220":
        dispatch(setMasterSettings(getParams("Master")["setting"]));
        dispatch(setModul1Settings(getParams("Modul1")["setting"]));
        dispatch(setModul2Settings(getParams("Modul2")["setting"]));
        dispatch(setMasterHotspots(getParams("Master")["hotspot"]));
        dispatch(setModul1Hotspots(getParams("Modul1")["hotspot"]));
        dispatch(setModul2Hotspots(getParams("Modul2")["hotspot"]));
        dispatch(
          setGraphParamList({
            MS: getParams("Master")["setting"],
            MH: getParams("Master")["hotspot"],
            S1S: getParams("Modul1")["setting"],
            S1H: getParams("Modul1")["hotspot"],
            S2S: getParams("Modul2")["setting"],
            S2H: getParams("Modul2")["hotspot"],
          })
        );
        break;
      case "Nova 330":
        dispatch(setMasterSettings(getParams("Master")["setting"]));
        dispatch(setModul1Settings(getParams("Modul1")["setting"]));
        dispatch(setModul2Settings(getParams("Modul2")["setting"]));
        dispatch(setModul3Settings(getParams("Modul3")["setting"]));
        dispatch(setModul4Settings(getParams("Modul4")["setting"]));
        dispatch(setMasterHotspots(getParams("Master")["hotspot"]));
        dispatch(setModul1Hotspots(getParams("Modul1")["hotspot"]));
        dispatch(setModul2Hotspots(getParams("Modul2")["hotspot"]));
        dispatch(setModul3Hotspots(getParams("Modul3")["hotspot"]));
        dispatch(
          setGraphParamList({
            MS: getParams("Master")["setting"],
            MH: getParams("Master")["hotspot"],
            S1S: getParams("Modul1")["setting"],
            S1H: getParams("Modul1")["hotspot"],
            S2S: getParams("Modul2")["setting"],
            S2H: getParams("Modul2")["hotspot"],
            S3S: getParams("Modul3")["setting"],
            S3H: getParams("Modul3")["hotspot"],
          })
        );
        break;
      case "Nova 440":
        dispatch(setMasterSettings(getParams("Master")["setting"]));
        dispatch(setModul1Settings(getParams("Modul1")["setting"]));
        dispatch(setModul2Settings(getParams("Modul2")["setting"]));
        dispatch(setModul3Settings(getParams("Modul3")["setting"]));
        dispatch(setModul4Settings(getParams("Modul4")["setting"]));
        dispatch(setMasterHotspots(getParams("Master")["hotspot"]));
        dispatch(setModul1Hotspots(getParams("Modul1")["hotspot"]));
        dispatch(setModul2Hotspots(getParams("Modul2")["hotspot"]));
        dispatch(setModul3Hotspots(getParams("Modul3")["hotspot"]));
        dispatch(setModul4Hotspots(getParams("Modul4")["hotspot"]));
        dispatch(
          setGraphParamList({
            MS: getParams("Master")["setting"],
            MH: getParams("Master")["hotspot"],
            S1S: getParams("Modul1")["setting"],
            S1H: getParams("Modul1")["hotspot"],
            S2S: getParams("Modul2")["setting"],
            S2H: getParams("Modul2")["hotspot"],
            S3S: getParams("Modul3")["setting"],
            S3H: getParams("Modul3")["hotspot"],
            S4S: getParams("Modul4")["setting"],
            S4H: getParams("Modul4")["hotspot"],
          })
        );
        break;

      default:
        break;
    }
  }, [dispatch, HPModel, controllerDescription]);

  function timeout(delay: number) {
    return new Promise((res) => setTimeout(res, delay));
  }

  const handleGWDataUpdate = (data: any) => {
    //console.log(data)
    switch (data.action) {
      case "tsupdate":
        var d = new Date();
        var ts = d.toLocaleTimeString() + " - " + d.toLocaleDateString();
        dispatch(setLastUpdate(ts));
        break;
      case "VPNIP":
        dispatch(setvpnIP(data.data.VPNIP));
        break;
      default:
        break;
    }
  };

  const handleDatagradData = (data: any) => {
    if (MCXLiveData.current) {
      handleGWDataUpdate({ action: "tsupdate" });
      //console.log(data)
      switch (data.HP) {
        case "M1H":
          dispatch(setModul1Hotspots(data.dataset));
          break;
        case "M1S":
          dispatch(setModul1Settings(data.dataset));
          break;
        case "M2H":
          dispatch(setModul2Hotspots(data.dataset));
          break;
        case "M2S":
          dispatch(setModul2Settings(data.dataset));
          break;
        case "M3H":
          dispatch(setModul3Hotspots(data.dataset));
          break;
        case "M3S":
          dispatch(setModul3Settings(data.dataset));
          break;
        case "M4H":
          dispatch(setModul4Hotspots(data.dataset));
          break;
        case "M4S":
          dispatch(setModul4Settings(data.dataset));
          break;
        case "MaH":
          dispatch(setMasterHotspots(data.dataset));
          break;
        case "MaS":
          dispatch(setMasterSettings(data.dataset));
          break;

        default:
          break;
      }
    }
  };

  const handleDataChangeAnswer = (e: any) => {
    //console.log(e)
    if ("change" in e) {
      if (e.changed === "True") {
        dispatch(
          setChangeSettingsState({
            DataMessage: "Value is changed",
            DataState: 1,
          })
        );
      } else if (e.changed === "False") {
        dispatch(
          setChangeSettingsState({
            DataMessage: "Value did not change",
            DataState: 3,
          })
        );
      }
      timeout(5000).then(() => {
        dispatch(setChangeSettingsState({ DataMessage: "", DataState: 0 }));
      });
    } else if ("updatedGW" in e) {
      //console.log(e['updateGW'])
      switch (e["updatedGW"]) {
        case "downloading":
          dispatch(setUpdateGWState("downloading"));
          break;
        case "restarting":
          dispatch(setUpdateGWState("restarting"));
          timeout(20000).then(() => {
            dispatch(setUpdateGWState("done"));
            PubSub.publish(handlerTopic, { user: "True", id: username });

            dispatch(setMcxGWRestarted(true));

            timeout(4000).then(() => {
              dispatch(setUpdateGWState("nothing"));
              window.location.reload();
            });
          });
          break;

        default:
          break;
      }
    } else if ("updateMCX" in e) {
      //console.log(e)
      switch (e["updateMCX"]["state"]) {
        case "file_on_gateway":
          dispatch(setMcxFileOnGateway(true));
          break;
        case "done":
          dispatch(setMcxFileOnGateway(true));
          dispatch(setMcxFileUploadProgress(100));
          handleUpdateGatewayAfterMCXUpdate();
          break;
        case "uploading":
          dispatch(setMcxFileUploadProgress(e["updateMCX"]["progress"]));
          break;

        default:
          break;
      }
    }
  };

  React.useEffect(() => {
    const getParams = (ModulName: any) => {
      let ID: number;
      let ModulNumb: string;
      let out: any;
      const settingList: any[] = [];
      const hotspotList: any[] = [];

      switch (ModulName) {
        case "Master":
          ID = 100;
          ModulNumb = "MCX";
          break;
        case "Modul1":
          ID = 1;
          ModulNumb = "1";
          break;
        case "Modul2":
          ID = 1;
          ModulNumb = "2";
          break;
        case "Modul3":
          ID = 2;
          ModulNumb = "1";
          break;
        case "Modul4":
          ID = 2;
          ModulNumb = "2";
          break;
        default:
          ID = 1;
          break;
      }
      //console.log(controllerDescription)
      if (ID in controllerDescription) {
        Object.entries(controllerDescription[ID]["data"]).map(
          ([modbusAdr, value]: any) => {
            if (ID === 1 || ID === 2) {
              if (value["Modulnumber"] === ModulNumb) {
                if (value["ReadWrite"] === "rw") {
                  settingList.push({
                    id: modbusAdr,
                    col1: modbusAdr,
                    col2: value["Name"],
                    col3: value["Default"],
                    col4: "",
                    col5: value,
                  });
                } else {
                  hotspotList.push({
                    id: modbusAdr,
                    col1: modbusAdr,
                    col2: value["Name"],
                    col3: "",
                    col5: value,
                  });
                }
              } else if (
                value["Modulnumber"] === null ||
                value["Modulnumber"] === "MCX"
              ) {
                if (value["ReadWrite"] === "rw") {
                  settingList.push({
                    id: modbusAdr,
                    col1: modbusAdr,
                    col2: value["Name"],
                    col3: value["Default"],
                    col4: "",
                    col5: value,
                  });
                } else {
                  hotspotList.push({
                    id: modbusAdr,
                    col1: modbusAdr,
                    col2: value["Name"],
                    col3: "",
                    col5: value,
                  });
                }
              }
            } else if (ID === 100) {
              if (value["ReadWrite"] === "rw") {
                settingList.push({
                  id: modbusAdr,
                  col1: modbusAdr,
                  col2: value["Name"],
                  col3: value["Default"],
                  col4: "",
                  col5: value,
                });
              } else {
                hotspotList.push({
                  id: modbusAdr,
                  col1: modbusAdr,
                  col2: value["Name"],
                  col3: "",
                  col5: value,
                });
              }
            }
          }
        );
        out = { setting: settingList, hotspot: hotspotList };
      } else {
        out = { setting: [], hotspot: [] };
      }
      //console.log({"setting":settingList, "hotspot": hotspotList})
      return out;
    };

    let liveData;
    let gwData;
    let dataChanged;
    let Webworker;
    if(username ===""){
      
    
    Auth.currentAuthenticatedUser().then((user) => {
      //console.log(user);
      dispatch(setCognitoUsername(user.username));
      setUsername(user.username);
      user = user.username;
    });
    //console.log(process.env.PUBLIC_URL)

    Webworker = new Worker(
      process.env.PUBLIC_URL + "/js/worker/loopMBWorker.js"
    );
    setWebWorker(Webworker);
    Webworker.addEventListener("message", (event) => {
      //console.log(event.data)
      if ("TSData" in event.data) {
        //console.log(event.data.TSData)
        //handleGraphData(event.data.TSData)
        dispatch(setGraphData(event.data.TSData));
      } else {
        handleDatagradData(event.data);
      }

      //console.log(apiRefM1H.length())
    });

    Webworker.postMessage({
      params: {
        masterSettings: getParams("Master")["setting"],
        modul1Settings: getParams("Modul1")["setting"],
        modul2Settings: getParams("Modul2")["setting"],
        modul3Settings: getParams("Modul3")["setting"],
        modul4Settings: getParams("Modul4")["setting"],
        masterHotspots: getParams("Master")["hotspot"],
        modul1Hotspots: getParams("Modul1")["hotspot"],
        modul2Hotspots: getParams("Modul2")["hotspot"],
        modul3Hotspots: getParams("Modul3")["hotspot"],
        modul4Hotspots: getParams("Modul4")["hotspot"],
      },
    });
  
    
    liveData = PubSub.subscribe(liveDataTopic).subscribe({
      next: (data) => {
        //console.log(data)
        Webworker.postMessage({ data: data.value });
        //handleLiveData(data.value);
      },
      error: (error) => console.error(error),
      complete: () => console.log("Done"),
    });

    gwData = PubSub.subscribe(gwDataTopic).subscribe({
      next: (data) => {
        //console.log(data)
        handleGWDataUpdate({ action: "VPNIP", data: data.value });
        //handleLiveData(data.value);
      },
      error: (error) => console.error(error),
      complete: () => console.log("Done"),
    });

    dataChanged = PubSub.subscribe(changedDataTopic).subscribe({
      next: (data) => {
        //console.log(data)
        handleDataChangeAnswer(data.value);
        //Webworker.postMessage({"data": data.value})
        //handleLiveData(data.value);
      },
      error: (error) => console.error(error),
      complete: () => console.log("Done"),
    });

    

    
    
    }
    if(username !==""){
      timeout(2000).then(() => {
      PubSub.publish(handlerTopic, { user: "True", id: username });
      window.addEventListener("unload", cleanup);
      window.addEventListener("unload", onClose);
    })}


    function onClose () {
      PubSub.publish(handlerTopic, { user: "False", id: username });
    }
    

    async function cleanup(event: any) {
      console.log("clean")
      event.preventDefault();
      Webworker.terminate();
      // Webworker = undefined;
      //PubSub.publish(handlerTopic, { user: "False", id: username });
      await liveData.unsubscribe();
      await dataChanged.unsubscribe();
      await gwData.unsubscribe();
    }
    

    return () => {
      window.removeEventListener("unload", cleanup);
    };

  
      

    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [liveDataTopic, handlerTopic, liveDataTopic, MCXLiveData, dispatch, username,window]);

  

  return { webWorker };
}
