import React from "react";
import { API } from "aws-amplify";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import { GridColDef } from "@mui/x-data-grid";

type Output = {
  id: string;
  col1: string;
  col2: string;
  col3: string;
  isAdmin: boolean;
  userEnabled: boolean;
  emailVerified: boolean;
  gotIdentity: boolean;
};

type UsersLogicProps = {
  rows: Output[];
  setRows: React.Dispatch<React.SetStateAction<Output[]>>;
};
export default function UsersLogic(props: UsersLogicProps) {
  const { rows, setRows } = props;

  const [fullRows, setFullRows] = React.useState<Output[]>([]);
  const [searchText, setSearchText] = React.useState("");
  const [toastOpen, setOpenToast] = React.useState(false);

  const columns: GridColDef[] = [
    { field: "col1", headerName: "Email", width: 350 },
    { field: "col2", headerName: "Created", width: 140 },
    { field: "col3", headerName: "Status", width: 100 },
    {
      field: "col4",
      headerName: "Actions",
      width: 550,
      align: "right",
      renderCell: (cellValues) => {
        if (cellValues.row.gotIdentity) {
          let admBtn;
          if (cellValues.row.isAdmin === "No") {
            admBtn = (
              <Button
                size="small"
                variant="contained"
                value={cellValues.row.id}
                onClick={(event) => {
                  handleClickAdmin(event);
                }}
              >
                Add Admin rights
              </Button>
            );
          } else {
            admBtn = (
              <Button
                size="small"
                variant="contained"
                value={cellValues.row.id}
                onClick={(event) => {
                  handleClickAdmin(event);
                }}
              >
                Remove Admin rights
              </Button>
            );
          }
          return (
            <Stack spacing={2} direction="row" justifyContent="flex-end">
              {admBtn}

              {cellValues.row.userEnabled ? (
                <>
                  <Button
                    size="small"
                    variant="outlined"
                    value={cellValues.row.id}
                    onClick={(event) => {
                      disableAccount(cellValues.row, true);
                    }}
                  >
                    Disable Account
                  </Button>

                  {/* <Button
                    size="small"
                    variant="outlined"
                    value={cellValues.row.id}
                    onClick={(event) => {
                      sendPasswordResetEmail(cellValues.row?.id);
                    }}
                  >
                    Reset Password
                  </Button> */}
                </>
              ) : (
                <Button
                  size="small"
                  variant="outlined"
                  value={cellValues.row.id}
                  onClick={(event) => {
                    disableAccount(cellValues.row, false);
                  }}
                >
                  Enable Account
                </Button>
              )}
            </Stack>
          );
        } else {
          return "";
        }
      },
    },
  ];



  const disableAccount = (row: Output, disable: boolean) => {
    const apiName = "ThermonovaAPI";
    const path = "/users/user";
    const myInit = {
      // OPTIONAL
      body: {
        username: row?.id,
        disable,
      },
    };

    API.post(apiName, path, myInit)
      .then((response) => {
        const index = rows.findIndex((item) => item.id === row?.id);
        const output = [...rows];
        output[index].userEnabled = !disable;

        setRows(output);
        setFullRows(output);

        setOpenToast(true);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  // const sendPasswordResetEmail = (username: string) => {
  //   const apiName = "ThermonovaAPI";
  //   const path = "/users/user";
  //   const myInit = {
  //     // OPTIONAL
  //     queryStringParameters: {
  //       username,
  //     },
  //   };

  //   API.get(apiName, path, myInit)
  //     .then((response) => {
  //       // console.log({ response });
  //       setOpenToast(true);
  //     })
  //     .catch((error) => {
  //       console.log(error.response);
  //     });
  // };

  const handleClickAdmin = (e: any) => {
    const user = e.target?.value;
    let action = "";
    if (e.target.innerText === "ADD ADMIN RIGHTS") {
      action = "grant_admin";
    } else if (e.target.innerText === "REMOVE ADMIN RIGHTS") {
      action = "revoke_admin";
    }

    pushUserData({ user: user, action: action });
  };

  type QuickSearchToolbarProps = {
    clearSearch: () => void;
    onChange: React.ChangeEventHandler<HTMLInputElement>;
    value: string;
  };

  function QuickSearchToolbar(props: QuickSearchToolbarProps) {
    return (
      <Box
        sx={{
          p: 0.5,
          pb: 0,
        }}
      >
        <TextField
          autoFocus
          variant="standard"
          value={props.value}
          onChange={props.onChange}
          placeholder="Search…"
          InputProps={{
            startAdornment: <SearchIcon fontSize="small" />,
            endAdornment: (
              <IconButton
                title="Clear"
                aria-label="Clear"
                size="small"
                style={{ visibility: props.value ? "visible" : "hidden" }}
                onClick={props.clearSearch}
              >
                <ClearIcon fontSize="small" />
              </IconButton>
            ),
          }}
          sx={{
            width: {
              xs: 1,
              sm: "auto",
            },
            m: (theme) => theme.spacing(1, 0.5, 1.5),
            "& .MuiSvgIcon-root": {
              mr: 0.5,
            },
            "& .MuiInput-underline:before": {
              borderBottom: 1,
              borderColor: "divider",
            },
          }}
        />
      </Box>
    );
  }
  QuickSearchToolbar.propTypes = {
    clearSearch: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired,
  };

  function escapeRegExp(value: string) {
    return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
  }

  const requestSearch = (searchValue: string) => {
    setSearchText(searchValue);
    const searchRegex = new RegExp(escapeRegExp(searchValue), "i");
    const filteredRows = fullRows.filter((row: any) => {
      return Object.keys(row).some((field: string) => {
        return searchRegex.test(row[field].toString());
      });
    });
    setRows(filteredRows);
  };

  function createData(data: any): Output {
    let ident;
    if ("custom:IdentityID" in data) {
      ident = true;
    } else {
      ident = false;
    }

    return {
      id: data["username"],
      col1: data["email"],
      col2: data["UserCreated"],
      col3: data["UserState"],
      isAdmin: data["isAdmin"],
      userEnabled: data["userEnabled"],
      emailVerified: data["email_verified"],
      gotIdentity: ident,
    };
  }

  function getUsers() {
    const apiName = "ThermonovaAPI";
    const path = "/users";
    const myInit = {
      // OPTIONAL
      headers: {}, // OPTIONAL
    };

    return API.get(apiName, path, myInit);
  }

  function pushUserData(data: { user: any; action: string }) {
    const apiName = "ThermonovaAPI";
    const path = "/users/user";
    const myInit = {
      // OPTIONAL
      body: data,
    };

    API.put(apiName, path, myInit)
      .then((response) => {
        if (response.Access === "Granted") {
          const output: Output[] = [];
          getUsers().then((response) => {
            response.forEach((element: any) => {
              output.push(createData(element));
            });
            setRows(output);
            setFullRows(output);
          });
        }
      })
      .catch((error) => {
        console.log(error.response);
      });
  }

  React.useEffect(() => {
    const output: Output[] = [];
    getUsers().then((response: any) => {
      response.forEach((element: any) => {
        output.push(createData(element));
      });
      setRows(output);
      setFullRows(output);
      //console.log(output)
    });
  }, [setRows, setFullRows]);

  return {
    columns,
    QuickSearchToolbar,
    rows,
    searchText,
    requestSearch,
    toastOpen,
    setOpenToast,
  };
}
