import * as React from "react";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Badge from "@mui/material/Badge";
import Container from "@mui/material/Container";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import LogoutIcon from "@mui/icons-material/Logout";
import RouterIcon from '@mui/icons-material/Router';
import WysiwygIcon from '@mui/icons-material/Wysiwyg';
import HeatPumpIcon from '@mui/icons-material/HeatPump';
// import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import DashboardIcon from "@mui/icons-material/Dashboard";
import PersonIcon from "@mui/icons-material/Person";
import ListSubheader from "@mui/material/ListSubheader";
//import ViewListIcon from "@mui/icons-material/ViewList";

import Copyright from "../Copyright";
import { MainViewLogic } from "./MainViewLogic";
import NavLogic from "../Navigation/NavLogic";
import ListItemButton from "@mui/material/ListItemButton";

export default function Mainview(props: any) {
  const { changeContent, showContent, headerTitle, ref } = MainViewLogic();
  const { mdTheme, Drawer, AppBar, toggleDrawer, open } = NavLogic();

  return (
    <ThemeProvider theme={mdTheme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar
          position="absolute"
          //  open={open}
        >
          <Toolbar
            sx={{
              pr: "24px", // keep right padding when drawer closed
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: "36px",
                ...(open && { display: "flex" }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
              className={"Header"}
            >
              {headerTitle}
            </Typography>
            <IconButton color="inherit" onClick={props.logOut}>
              <Badge color="secondary">
                <LogoutIcon />
              </Badge>
            </IconButton>
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <Toolbar
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              px: [1],
            }}
          >
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>
          <Divider />
          <List>
            <div >
              <ListItem dense onClick={() => changeContent("Dashboard")} style={{paddingLeft: '0px'}}>
                <ListItemButton>
                  <ListItemIcon>
                    <DashboardIcon />
                  </ListItemIcon>
                  <ListItemText primary="Dashboard" />
                </ListItemButton>
              </ListItem>
              <ListItem dense onClick={() => changeContent("Users")} style={{paddingLeft: '0px'}}>
                <ListItemButton>
                  <ListItemIcon>
                    <PersonIcon />
                  </ListItemIcon>
                  <ListItemText primary="Customers" />
                </ListItemButton>
              </ListItem>
            </div>
          </List>
          <Divider />
          <List>
            <div>
              <ListSubheader inset>Heatpumps</ListSubheader>
              <ListItem dense onClick={() => changeContent("HPTable")} style={{paddingLeft: '0px'}}>
                <ListItemButton>
                  <ListItemIcon>
                    <HeatPumpIcon />
                  </ListItemIcon>
                  <ListItemText primary="Overview" />
                </ListItemButton>
              </ListItem>
              <ListItem
                // button
                // name="Gateways"
                dense
                onClick={() => changeContent("Gateways")}
                style={{paddingLeft: '0px'}}
              >
                <ListItemButton>
                  <ListItemIcon>
                    <RouterIcon />
                  </ListItemIcon>
                  <ListItemText primary="Gateways" />
                </ListItemButton>
              </ListItem>
              <ListItem
                // button
                // name="Software"
                dense
                onClick={() => changeContent("Software") }
                style={{paddingLeft: '0px'}}
              >
                <ListItemButton>
                  <ListItemIcon>
                    <WysiwygIcon />
                  </ListItemIcon>
                  <ListItemText primary="Software" />
                </ListItemButton>
              </ListItem>
            </div>
          </List>
        </Drawer>
        <Box
          ref={ref}
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: "100vh",
            overflow: "auto",
          }}
        >
          <Toolbar />
          <Container maxWidth={false} sx={{ mt: 4, mb: 4 }}>
            {showContent()}

            <Copyright sx={{ pt: 4 }} />
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
}
