import React from "react";
import UsersLogic from "./UsersLogic";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import Paper from "@mui/material/Paper";
import { Snackbar } from "@mui/material";

export default function Users(props: any) {
  const {
    columns,
    QuickSearchToolbar,
    rows,
    searchText,
    requestSearch,
    toastOpen,
    setOpenToast,
  } = UsersLogic({ rows: props.rows, setRows: props.setRows });

  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <Box sx={{ height: props.height, width: 1 }}>
        <DataGrid
          disableSelectionOnClick
          components={{ Toolbar: QuickSearchToolbar }}
          rows={rows}
          columns={columns}
          componentsProps={{
            toolbar: {
              value: searchText,
              onChange: (
                event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
              ) => {
                requestSearch(event?.target.value);
              },
              clearSearch: () => requestSearch(""),
            },
          }}
        />

        <Snackbar
          open={toastOpen}
          autoHideDuration={6000}
          onClose={() => setOpenToast(false)}
          message="Operation successful"
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        />
      </Box>
    </Paper>
  );
}
