import React from "react";
import { PubSub } from "aws-amplify";

import Button from "@mui/material/Button";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
// import { useAppSelector } from "../../../hooks/redux";

import { useSelector } from "react-redux";

export default function OverviewGwStatus() {
  const { lastUpdate, vpnIP, updateGWState, HPData, handlerTopic } =
    useSelector((state: any) => state.heatpump);

  const [updateGWMessages, setUpdateGWMessages] = React.useState<any>("");

  const updateGW = () => {
    PubSub.publish(handlerTopic, { updateGW: "True" });
  };

  React.useEffect(() => {
    switch (updateGWState) {
      case "nothing":
        setUpdateGWMessages("");
        break;
      case "downloading":
        setUpdateGWMessages(
          <Alert severity="info">Gateway is downloading software.</Alert>
        );
        break;
      case "restarting":
        setUpdateGWMessages(
          <Alert severity="info">Gateway is restarting.</Alert>
        );
        break;
      case "done":
        setUpdateGWMessages(
          <Alert severity="success">Gateway is updated.</Alert>
        );
        break;
      case "error":
        setUpdateGWMessages(
          <Alert severity="error">Updating the gateway failed.</Alert>
        );

        break;

      default:
        setUpdateGWMessages("");
        break;
    }
  }, [updateGWState]);

  const showupdateGateway = (
    <Stack spacing={2}>
      <Button onClick={updateGW} variant="contained">
        Update Gateway
      </Button>
      {updateGWMessages}
    </Stack>
  );

  const renderSoftwareVersionLines = () => {
    switch (HPData.Model) {
      case "Nova 110":
        return (
          <React.Fragment>
            {showVersionLine(
              "Software Master",
              HPData.Controller["100"]["SoftwareModel"] +
                "." +
                HPData.Controller["100"]["SoftwareVersion"]
            )}
            {showVersionLine(
              "Software Slave 1",
              HPData.Controller["1"]["SoftwareModel"] +
                "." +
                HPData.Controller["1"]["SoftwareVersion"]
            )}
          </React.Fragment>
        );
      case "Nova 220":
        return (
          <React.Fragment>
            {showVersionLine(
              "Software Master",
              HPData.Controller["100"]["SoftwareModel"] +
                "." +
                HPData.Controller["100"]["SoftwareVersion"]
            )}
            {showVersionLine(
              "Software Slave 1",
              HPData.Controller["1"]["SoftwareModel"] +
                "." +
                HPData.Controller["1"]["SoftwareVersion"]
            )}
          </React.Fragment>
        );

      case "Nova 330":
        return (
          <React.Fragment>
            {showVersionLine(
              "Software Master",
              HPData.Controller["100"]["SoftwareModel"] +
                "." +
                HPData.Controller["100"]["SoftwareVersion"]
            )}
            {showVersionLine(
              "Software Slave 1",
              HPData.Controller["1"]["SoftwareModel"] +
                "." +
                HPData.Controller["1"]["SoftwareVersion"]
            )}
            {showVersionLine(
              "Software Slave 2",
              HPData.Controller["2"]["SoftwareModel"] +
                "." +
                HPData.Controller["2"]["SoftwareVersion"]
            )}
          </React.Fragment>
        );

      case "Nova 440":
        return (
          <React.Fragment>
            {showVersionLine(
              "Software Master",
              HPData.Controller["100"]["SoftwareModel"] +
                "." +
                HPData.Controller["100"]["SoftwareVersion"]
            )}
            {showVersionLine(
              "Software Slave 1",
              HPData.Controller["1"]["SoftwareModel"] +
                "." +
                HPData.Controller["1"]["SoftwareVersion"]
            )}
            {showVersionLine(
              "Software Slave 2",
              HPData.Controller["2"]["SoftwareModel"] +
                "." +
                HPData.Controller["2"]["SoftwareVersion"]
            )}
          </React.Fragment>
        );
        case "Nature Energy":
          return (
            <React.Fragment>
              {showVersionLine(
                "Software Master",
                HPData.Controller["1"]["SoftwareModel"] +
                  "." +
                  HPData.Controller["1"]["SoftwareVersion"]
              )}
              {showVersionLine(
                "Software Slave 1",
                HPData.Controller["2"]["SoftwareModel"] +
                  "." +
                  HPData.Controller["2"]["SoftwareVersion"]
              )}
              {showVersionLine(
                "Software Slave 2",
                HPData.Controller["3"]["SoftwareModel"] +
                  "." +
                  HPData.Controller["3"]["SoftwareVersion"]
              )}
              {showVersionLine(
                "Software Slave 3",
                HPData.Controller["4"]["SoftwareModel"] +
                  "." +
                  HPData.Controller["4"]["SoftwareVersion"]
              )}
              {showVersionLine(
                "Software Slave 5",
                HPData.Controller["5"]["SoftwareModel"] +
                  "." +
                  HPData.Controller["5"]["SoftwareVersion"]
              )}
            </React.Fragment>
          );

      default:
        return (
          <React.Fragment>
            {showVersionLine(
              "Software Master",
              HPData.Controller["1"]["SoftwareModel"] +
                "." +
                HPData.Controller["1"]["SoftwareVersion"]
            )}
          </React.Fragment>
        );
        
    }
  };

  const showVersionLine = (Titel: string, Value: string) => {
    return (
      <TableRow
        key={Titel}
        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
      >
        <TableCell align="left">{Titel}</TableCell>
        <TableCell align="right">{Value}</TableCell>
      </TableRow>
    );
  };

  return { showupdateGateway, vpnIP, lastUpdate, renderSoftwareVersionLines };
}
